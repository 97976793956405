import { render, staticRenderFns } from "./roleplay.vue?vue&type=template&id=12cb7b3d&scoped=true&"
import script from "./roleplay.vue?vue&type=script&lang=ts&"
export * from "./roleplay.vue?vue&type=script&lang=ts&"
import style0 from "./roleplay.vue?vue&type=style&index=0&id=12cb7b3d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12cb7b3d",
  null
  
)

export default component.exports